import React from 'react';

export const CheckIcon = (): JSX.Element => (
  <svg
    width="102"
    height="94"
    viewBox="0 0 102 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M99.0643 0.50556L46.7593 52.8085L31.5967 37.6442C30.922 36.9926 29.8499 37.0022 29.1873
      37.665C28.5238 38.3285 28.515 39.4005 29.1657 40.0751L45.545 56.4553C46.2165 57.1268 47.3046
      57.1268 47.9761 56.4553L101.497 2.93469C102.168 2.26319 102.168 1.17435 101.497 0.503628C100.825
      -0.167876 99.7364 -0.167876 99.0648 0.503628L99.0643 0.50556Z"
      fill="#10CB00"
    />
    <path
      d="M46.9555 93.9248C56.5527 93.9128 65.9161 90.9645 73.7895 85.4765C81.6631 79.9878 87.6682 72.222
      90.9987 63.2206C94.3298 54.22 94.8271 44.4158 92.424 35.1239C92.1778 34.2158 91.2481 33.6746 90.3368
      33.9096C89.4255 34.1438 88.8723 35.0672 89.0953 35.9817C90.0178 39.5687 90.4847 43.2581 90.4839 46.9617C90.4831
      56.5507 87.3151 65.8705 81.4712 73.4744C75.6282 81.0768 67.4382 86.5369 58.1716 89.0064C48.9064 91.475 39.0845 90.8147
      30.2325 87.1278C21.3805 83.44 13.9946 76.9329 9.22291 68.6156C4.45042 60.2991 2.55903 50.639 3.84117 41.137C5.12332
      31.635 9.50901 22.8214 16.3153 16.0671C23.1225 9.31357 31.9688 4.99664 41.4814 3.78797C50.994 2.57929 60.6395 4.54581
      68.9191 9.38315C69.3132 9.62937 69.7905 9.70532 70.2421 9.59419C70.693 9.48307 71.0799 9.19369 71.3149 8.79237C71.5492
      8.39106 71.6107 7.9122 71.4868 7.46452C71.3621 7.01685 71.0615 6.63871 70.653 6.41568C62.3206 1.54721 52.6901 -0.636833
      43.0721 0.162538C33.4534 0.961949 24.3153 4.70479 16.9012 10.8828C9.48704 17.0609 4.15524 25.3724 1.63229 34.6879C-0.889843
      44.0037 -0.481356 53.87 2.80345 62.9443C6.0875 72.0193 12.0887 79.8624 19.9887 85.4048C27.8893 90.948 37.3057 93.9218 46.9577
      93.9235L46.9555 93.9248Z"
      fill="#10CB00"
    />
  </svg>
);

export const AttentionIcon = (): JSX.Element => (
  <svg
    width="129"
    height="115"
    viewBox="0 0 129 115"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.4041 0.00308595C63.901 0.0259998 63.4198 0.321795 63.1719 0.760277C42.2368 37.8226 21.2062 74.8316 0.189976 111.849C-0.0662448
      112.301 -0.0631198 112.889 0.198308 113.337C0.459736 113.786 0.970085 114.079 1.48984 114.079H127.512C128.032 114.079 128.541 113.785 128.802
      113.336C129.063 112.887 129.066 112.3 128.809 111.848C107.774 74.8279 86.774 37.7922 65.7685 0.756544C65.4987 0.280546 64.9499 -0.0235763 64.403
      0.00143413L64.4041 0.00308595ZM64.4718 4.52549C84.6132 40.0573 104.782 75.5758 124.945 111.094H4.05219C24.2488 75.6025 44.3515 40.0627 64.4691
      4.52549H64.4718Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.4504 89.4337C63.6266 89.4472 62.9693 90.1232 62.9808 90.9471V94.3757C62.9725 94.7767 63.1256 95.1652 63.4068 95.4516C63.687 95.7381
      64.0723 95.8995 64.4733 95.8995C64.8743 95.8995 65.2597 95.7381 65.5399 95.4516C65.8211 95.1652 65.9742 94.7767 65.9659 94.3757V90.9471C65.9711
      90.5429 65.8138 90.1544 65.5274 89.8701C65.242 89.5847 64.8535 89.4274 64.4504 89.4337H64.4504Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.4504 36.3439C63.6266 36.3564 62.9683 37.0344 62.9808 37.8583V79.3628C62.9725 79.7648 63.1256 80.1523 63.4068 80.4387C63.687 80.7251
      64.0723 80.8876 64.4733 80.8876C64.8743 80.8876 65.2597 80.7251 65.5399 80.4387C65.8211 80.1523 65.9742 79.7648 65.9659 79.3628V37.8583C65.9721
      37.4542 65.8138 37.0657 65.5284 36.7803C65.2431 36.4949 64.8535 36.3376 64.4504 36.3439H64.4504Z"
    />
  </svg>
);

export const DistributorsIcon = (): JSX.Element => (
  <svg
    width="14"
    height="15"
    fill="currentColor"
    viewBox="0 0 14 15"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.187 9.503h-1.625V7.128a.125.125 0 0 0-.125-.125H7.562v-1.5H9.25a.25.25 0 0 0 .25-.25v-4.5a.25.25
     0 0 0-.25-.25h-4.5a.25.25 0 0 0-.25.25v4.5c0 .137.112.25.25.25h1.687v1.5H2.562a.125.125
      0 0 0-.125.125v2.375H.812a.25.25 0 0 0-.25.25v4.5c0 
       .137.113.25.25.25h4.5a.25.25 0 0 0 .25-.25v-4.5a.25.25 
       0 0 0-.25-.25h-1.75V8.128h6.875v1.375h-1.75a.25.25 0 0 
       0-.25.25v4.5c0 .137.113.25.25.25h4.5a.25.25 0 0 0 
       .25-.25v-4.5a.25.25 0 0 0-.25-.25zM4.375 10.69v2.625H1.75V10.69h2.625zm1.312-6.375V1.69h2.625v2.625H5.687zm6.563
        9H9.625V10.69h2.625v2.625z"
    />
  </svg>
);

export const EuroRefreshIcon = (): JSX.Element => (
  <svg
    width="143"
    height="178"
    viewBox="0 0 143 178"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.02062 97.1439C-1.42609 59.547 25.547 25.3375 63.1475 20.8915C69.3761 20.1527 75.6573 20.2809 81.8655 21.2554L65.404
      30.8348C64.7948 31.1862 64.5894 31.965 64.9422 32.5715C65.1807 32.9767 65.6039 33.2042 66.0436 33.2042C66.2613 33.2042 66.4805
      33.1463 66.6817 33.0319L86.067 21.7528C86.4764 21.5157 86.6969 21.087 86.6955 20.646C86.6969 20.6019 86.6955 20.5591 86.6914
      20.515C86.69 20.5026 86.69 20.4916 86.6872 20.4792C86.661 20.2187 86.5591 19.9747 86.3923 19.7804L75.2554 0.632211C74.8998
      0.0229679 74.1169 -0.179642 73.5159 0.173215C72.9067 0.524693 72.7013 1.30345 73.0542 1.9127L82.8982 18.8389C76.2574 17.7362
      69.5242 17.5736 62.8474 18.3661C23.8532 22.978 -4.11429 58.4542 0.497554 97.4414C2.72909 116.332 12.1859 133.221 27.1245
      144.996C27.3575 145.179 27.6359 145.269 27.9116 145.269C28.2865 145.269 28.6586 145.104 28.9095 144.785C29.3437 144.234
      29.2499 143.436 28.6986 143C14.2978 131.644 5.17854 115.357 3.02258 97.1428L3.02062 97.1439Z"
      fill="white"
    />
    <path
      d="M141.919 80.7114C139.688 61.8212 130.231 44.9317 115.292 33.1604C114.741 32.7275 113.944 32.8199 113.507 33.3712C113.073
      33.9226 113.167 34.7234 113.718 35.1562C128.123 46.5083 137.241 62.792 139.397 81.0065C143.843 118.607 116.876 152.813 79.2768
      157.262C72.8869 158.018 66.4473 157.872 60.0958 156.83L76.9832 147.01C77.5924 146.656 77.7978 145.88 77.445 145.274C77.0893
      144.663 76.3092 144.463 75.7055 144.813L56.3149 156.087C56.0227 156.255 55.8104 156.535 55.7236 156.861C55.6685 157.065 55.6836
      157.271 55.7319 157.472C55.7016 157.941 55.9317 158.377 56.3149 158.62L67.1254 177.209C67.3611 177.615 67.7897 177.841 68.2267
      177.841C68.4445 177.841 68.6636 177.786 68.8649 177.669C69.4741 177.317 69.6795 176.539 69.3266 175.932L59.67 159.326C63.4949
      159.955 67.3503 160.283 71.2095 160.283C73.9992 160.283 76.7917 160.119 79.576 159.789C118.567 155.174 146.534 119.701
      141.919 80.7104L141.919 80.7114Z"
      fill="white"
    />
    <path
      d="M49.832 95.424C49.768 94.08 49.736 92.576 49.736 90.912C49.736 89.248 49.8 87.584 49.928 85.92H46.568V83.232H50.312C51.336
      77.664 53.544 73.056 56.936 69.408C61.672 64.352 67.912 61.824 75.656 61.824C80.648 61.824 84.968 62.816 88.616 64.8C89.896
      65.504 90.92 66.272 91.688 67.104L89.192 70.944C88.552 70.24 86.984 69.44 84.488 68.544C82.056 67.648 79.304 67.2 76.232
      67.2C73.224 67.2 70.504 67.648 68.072 68.544C62.504 70.656 58.76 75.552 56.84 83.232H83.24V85.92H56.456C56.264 87.584
      56.168 89.248 56.168 90.912C56.168 92.576 56.2 94.08 56.264 95.424H83.24V98.112H56.648C57.48 102.912 59.24 106.752 61.928
      109.632C65.32 113.344 69.992 115.2 75.944 115.2C80.488 115.2 84.584 114.24 88.232 112.32C89.128 111.872 89.736 111.488 90.056
      111.168L92.264 115.2C89.768 117.888 85.224 119.616 78.632 120.384C77.608 120.448 76.584 120.48 75.56 120.48C71.72 120.48
      67.944 119.712 64.232 118.176C58.472 115.744 54.28 111.104 51.656 104.256C51.016 102.4 50.504 100.352 50.12 98.112H46.088V95.424H49.832Z"
      fill="white"
    />
  </svg>
);

export const LockIcon = (): JSX.Element => (
  <svg
    width="133"
    height="160"
    viewBox="0 0 133 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M107.571 42.6388C106.86 42.0665 105.82 42.1804 105.248 42.8917C104.676 43.603 104.79 44.6426 105.501 45.2149C112.754 51.0399 118.677
      58.4486 122.739 66.9131C126.648 75.0584 128.836 84.1941 128.836 93.8522C128.836 111.067 121.859 126.65 110.578 137.929C99.2973 149.21
      83.7122 156.188 66.5005 156.188C49.2857 156.188 33.7022 149.211 22.4234 137.929C11.1431 126.649 4.16491 111.064 4.16491 93.8522C4.16491
      84.3644 6.27957 75.376 10.0638 67.3374C13.9975 58.9793 19.7367 51.6354 26.777 45.8077C27.4809 45.225 27.58 44.1825 26.9973 43.4786C26.4147
      42.7747 25.3721 42.6756 24.6682 43.2583C17.2596 49.3908 11.2187 57.1235 7.07489 65.9268C3.08361 74.4075 0.851929 83.8733 0.851929
      93.8525C0.851929 111.981 8.2 128.393 20.0807 140.273C31.9614 152.154 48.3729 159.502 66.5011 159.502C84.6294 159.502 101.041 152.154
      112.922 140.273C124.802 128.392 132.15 111.981 132.15 93.8525C132.15 83.7033 129.84 74.0838 125.716 65.4906C121.435 56.5692 115.202
      48.7669 107.573 42.6404L107.571 42.6388Z"
      fill="white"
    />
    <path
      d="M25.3304 44.2326V16.9709C25.3304 12.4398 27.1833 8.32281 30.1674 5.33853C33.1516 2.35433 37.2701 0.501526 41.7997 0.501526H91.2033C95.7344
      0.501526 99.8529 2.35444 102.836 5.33853C105.82 8.32274 107.673 12.4413 107.673 16.9709V44.2326C107.673 45.148 106.93 45.8888 106.016
      45.8888H26.9896C26.0742 45.8888 25.3334 45.1465 25.3334 44.2326H25.3304ZM28.6429 16.9709V42.5763H104.361V16.9709C104.361 13.3552 102.881
      10.065 100.495 7.67962C98.11 5.29432 94.8213 3.81403 91.2041 3.81403H41.8005C38.1834 3.81403 34.8946 5.29432 32.5092 7.67962C30.1239
      10.0649 28.6436 13.3536 28.6436 16.9709H28.6429Z"
      fill="white"
    />
    <path
      d="M66.5 83.6759C68.8675 83.6759 71.0118 84.6357 72.5647 86.1884C74.1174 87.7412 75.0772 89.8854 75.0772 92.2531C75.0772 93.6402 74.743
      94.9549 74.1514 96.1217C73.6723 97.0682 73.0231 97.9126 72.2453 98.615V114.132C72.2453 115.216 71.8001 116.205 71.0829 116.925L71.077
      116.931C70.3569 117.648 69.3675 118.094 68.2836 118.094H64.7197C63.6357 118.094 62.6464 117.648 61.9262 116.931L61.9203 116.925C61.2031
      116.205 60.758 115.216 60.758 114.132V98.615C59.9801 97.9125 59.331 97.0682 58.8518 96.1217C58.2603 94.955 57.9261 93.6403 57.9261
      92.2531C57.9261 89.8856 58.8858 87.7413 60.4385 86.1884C61.9913 84.6357 64.1355 83.6759 66.5033 83.6759H66.5ZM70.2221 88.5307C69.2698
      87.5784 67.9536 86.9898 66.5 86.9898C65.0463 86.9898 63.7302 87.5784 62.7779 88.5307C61.8255 89.4831 61.237 90.7992 61.237 92.2528C61.237
      93.115 61.4396 93.9239 61.8004 94.6337C62.176 95.3731 62.7232 96.0134 63.3886 96.5029L64.0674 97.0013V114.132C64.0674 114.314 64.1384 114.475
      64.2537 114.59L64.2596 114.596C64.3735 114.71 64.5362 114.782 64.7181 114.782H68.282C68.4639 114.782 68.6251 114.711 68.7404 114.596L68.7463
      114.59C68.8602 114.476 68.9326 114.314 68.9326 114.132V97.0028L69.6114 96.5044C70.2783 96.0149 70.8255 95.3746 71.1996 94.6352C71.559 93.9254
      71.7631 93.1165 71.7631 92.2544C71.7631 90.8007 71.1745 89.4846 70.2221 88.5322L70.2221 88.5307Z"
      fill="white"
    />
  </svg>
);

export const MessageSent = (): JSX.Element => (
  <svg width="202" height="81" viewBox="0 0 202 81" fill="#fff">
    <path
      d="M89.5057 0.00135009H192.925C193.765 0.00135009 194.418 0.093962 195.166 0.188224C198.992 
      1.1209 201.513 4.38862 201.513 8.30801C201.513 9.05547 201.42 9.80128 201.233 10.6414L187.42
      70.3755C186.767 73.1753 185.086 75.6953 182.753 77.47C180.512 79.337 177.713 80.4566 174.913
      80.4566H71.4935C70.746 80.4566 70.0002 80.364 69.347 80.2697C67.3874 79.8033 65.8941 78.7764
      64.7728 77.3758C63.6532 75.9751 63 74.1097 63 72.1483C63 71.4008 63.0926 70.655 63.2795
      69.8149L77.0928 10.0807C78.3067 4.57383 83.8135 0 89.5071 0L89.5057 0.00135009ZM83.9988
      4.20175L132.345 38.8302L197.214 3.82963C196.467 3.17641 195.628 2.80268 194.601 2.52323C194.135
      2.43062 193.575 2.33636 192.921 2.33636H89.502C87.635 2.33636 85.6753 2.98958 83.9951 4.2034L83.9988
      4.20175ZM198.616 5.69502L132.815 41.2561C132.441 41.443 131.975 41.443 131.508 41.1635L82.1336
      5.69513C80.8272 7.09581 79.8002 8.77595 79.4265 10.6415L65.6131 70.3757C65.4263 70.9363 65.4263
      71.5895 65.4263 72.1485C65.4263 73.6417 65.8 74.9482 66.6401 75.8825C67.3875 76.9095 68.5071
      77.6553 69.9061 77.9364C70.3725 78.029 70.9331 78.1233 71.492 78.1233H174.911C177.152 78.1233
      179.486 77.1906 181.351 75.6973C183.124 74.204 184.617 72.1501 185.085 69.8166L198.898
      10.0824C198.991 9.5218 199.085 8.86858 199.085 8.30963C199.085 7.37696 198.898 6.53688 198.619
      5.69679L198.616 5.69502Z"
      fill="white"
    />
    <path
      d="M1.11954 70.7468C0.466316 70.7468 0 70.1862 0 69.533C0 68.8797 0.466359 68.3191 1.11954 
      68.3191H46.4811C47.2286 68.3191 47.6949 68.8797 47.6949 69.533C47.6949 70.1862 47.2286 70.7468
      46.4811 70.7468H1.11954Z"
      fill="white"
    />
    <path
      d="M26.1308 50.3076C25.4776 50.3076 24.917 49.747 24.917 49.0938C24.917 48.4406 25.4776 47.9743 
      26.1308 47.9743H54.8786C55.5318 47.9743 55.9981 48.4406 55.9981 49.0938C55.9981 49.747 55.5318
      50.3076 54.8786 50.3076H26.1308Z"
      fill="white"
    />
    <path
      d="M117.883 37.332C118.443 37.0526 119.189 37.2394 119.469 37.7984C119.842 38.359 119.656 39.0122 
      119.095 39.3843L72.1459 66.9183C71.5853 67.2921 70.8394 67.1052 70.4657 66.5446C70.1862 65.984
      70.3731 65.2382 70.932 64.9587L117.881 37.3303L117.883 37.332Z"
      fill="white"
    />
    <path
      d="M144.761 41.3454C144.2 40.9717 144.108 40.2258 144.482 39.6652C144.855 39.1046 145.601 39.012 
      146.162 39.3857L180.883 64.7734C181.444 65.1471 181.536 65.8929 181.162 66.3593C180.789 66.9199
      180.043 67.0125 179.482 66.6388L144.761 41.3454Z"
      fill="white"
    />
    <path
      d="M37.9846 29.9616C37.3313 29.9616 36.7708 29.401 36.7708 28.7478C36.7708 28.0945 37.3313 
      27.5339 37.9846 27.5339H61.7847C62.4379 27.5339 62.9042 28.0945 62.9042 28.7478C62.9042 29.401
      62.4379 29.9616 61.7847 29.9616H37.9846Z"
      fill="white"
    />
  </svg>
);

export const PinRecover = (): JSX.Element => (
  <svg width="143" height="179" viewBox="0 0 143 179" fill="#fff">
    <path
      d="M3.31017 98.0216C-1.13654 60.4246 25.8366 26.2151 63.437 21.7691C69.6657 21.0303 75.9469 
      21.1585 82.155 22.133L65.6936 31.7124C65.0843 32.0639 64.8789 32.8426 65.2318 33.4491C65.4703
      33.8543 65.8934 34.0818 66.3331 34.0818C66.5509 34.0818 66.7701 34.0239 66.9713 33.9095L86.3566
      22.6304C86.7659 22.3933 86.9865 21.9647 86.9851 21.5236C86.9864 21.4795 86.9851 21.4368 86.9809
      21.3927C86.9796 21.3802 86.9795 21.3692 86.9768 21.3568C86.9506 21.0963 86.8486 20.8523 86.6818
      20.658L75.5449 1.50984C75.1893 0.900592 74.4064 0.697982 73.8055 1.05084C73.1962 1.40232 72.9909
      2.18108 73.3437 2.79032L83.1877 19.7165C76.547 18.6138 69.8137 18.4512 63.137 19.2438C24.1427
      23.8556 -3.82474 59.3319 0.787105 98.3191C3.01864 117.209 12.4755 134.099 27.4141 145.874C27.647
      146.057 27.9254 146.147 28.2011 146.147C28.576 146.147 28.9482 145.981 29.199 145.663C29.6332
      145.111 29.5395 144.313 28.9881 143.878C14.5873 132.521 5.46809 116.235 3.31213 98.0204L3.31017
      98.0216Z"
      fill="white"
    />
    <path
      d="M142.209 81.589C139.977 62.6988 130.52 45.8093 115.582 34.038C115.03 33.6052 114.234 33.6975 
      113.797 34.2489C113.363 34.8002 113.456 35.601 114.008 36.0338C128.413 47.3859 137.531 63.6696
      139.686 81.8841C144.133 119.485 117.165 153.691 79.5663 158.14C73.1764 158.895 66.7368 158.749
      60.3854 157.707L77.2728 147.888C77.882 147.534 78.0874 146.758 77.7345 146.151C77.3789 145.541
      76.5987 145.341 75.995 145.691L56.6045 156.964C56.3123 157.132 56.1 157.412 56.0131 157.739C55.958
      157.943 55.9732 158.148 56.0214 158.349C55.9911 158.818 56.2213 159.255 56.6044 159.498L67.4149
      178.086C67.6506 178.493 68.0793 178.719 68.5162 178.719C68.734 178.719 68.9532 178.664 69.1544
      178.547C69.7637 178.195 69.969 177.416 69.6162 176.81L59.9595 160.204C63.7845 160.832 67.6398
      161.16 71.499 161.16C74.2888 161.16 77.0812 160.996 79.8656 160.667C118.856 156.052 146.824 120.579
      142.208 81.588L142.209 81.589Z"
      fill="white"
    />
    <path
      d="M93.4231 69.9669C93.0607 69.6754 92.5311 69.7334 92.2396 70.0957C91.948 70.4581 92.006 70.9877 
      92.3684 71.2792C96.0635 74.2467 99.0808 78.021 101.15 82.333C103.141 86.4826 104.256 91.1366 104.256
      96.0568C104.256 104.827 100.702 112.765 94.9546 118.511C89.208 124.258 81.2685 127.813 72.5002
      127.813C63.7305 127.813 55.7917 124.258 50.0459 118.511C44.2993 112.765 40.7444 104.825 40.7444
      96.0568C40.7444 91.2234 41.8217 86.6444 43.7495 82.5492C45.7535 78.2913 48.6772 74.5501 52.2637
      71.5813C52.6223 71.2844 52.6728 70.7533 52.376 70.3947C52.0792 70.0361 51.5481 69.9857 51.1895
      70.2825C47.4153 73.4066 44.3378 77.3459 42.2268 81.8306C40.1935 86.151 39.0566 90.9732 39.0566
      96.0569C39.0566 105.292 42.8 113.653 48.8524 119.705C54.9049 125.757 63.2654 129.501 72.5006
      129.501C81.7357 129.501 90.0965 125.757 96.1487 119.705C102.201 113.653 105.945 105.292 105.945
      96.0569C105.945 90.8866 104.768 85.9861 102.667 81.6084C100.486 77.0635 97.3105 73.0888 93.4238
      69.9678L93.4231 69.9669Z"
      fill="white"
    />
    <path
      d="M51.5269 70.7789V56.8908C51.5269 54.5825 52.4708 52.4852 53.991 50.9649C55.5112 49.4446 57.6094 
      48.5008 59.9169 48.5008H85.0848C87.3931 48.5008 89.4912 49.4447 91.0107 50.9649C92.5309 52.4852
      93.4748 54.5833 93.4748 56.8908V70.7789C93.4748 71.2452 93.0967 71.6226 92.6311 71.6226H52.3721C51.9058
      71.6226 51.5284 71.2444 51.5284 70.7789H51.5269ZM53.2144 56.8908V69.9351H91.7877V56.8908C91.7877
      55.0489 91.0336 53.3727 89.8185 52.1575C88.6033 50.9424 86.9279 50.1883 85.0852 50.1883H59.9173C58.0746
      50.1883 56.3992 50.9424 55.184 52.1575C53.9689 53.3727 53.2147 55.0481 53.2147 56.8908H53.2144Z"
      fill="white"
    />
    <path
      d="M72.5002 90.8726C73.7063 90.8726 74.7986 91.3616 75.5897 92.1526C76.3808 92.9436 76.8697 94.0359 
      76.8697 95.2421C76.8697 95.9488 76.6994 96.6185 76.3981 97.2129C76.154 97.6951 75.8233 98.1253 75.427
      98.4831V106.388C75.427 106.94 75.2002 107.444 74.8349 107.811L74.8319 107.814C74.465 108.179 73.961
      108.406 73.4088 108.406H71.5932C71.041 108.406 70.537 108.179 70.1701 107.814L70.1671 107.811C69.8017
      107.444 69.575 106.94 69.575 106.388V98.4831C69.1787 98.1252 68.848 97.6951 68.6039 97.2129C68.3026
      96.6186 68.1323 95.9488 68.1323 95.2421C68.1323 94.036 68.6213 92.9437 69.4123 92.1526C70.2033 91.3616
      71.2956 90.8726 72.5018 90.8726H72.5002ZM74.3963 93.3458C73.9112 92.8607 73.2407 92.5609 72.5002
      92.5609C71.7596 92.5609 71.0891 92.8607 70.604 93.3458C70.1188 93.831 69.819 94.5015 69.819 95.242C69.819
      95.6812 69.9222 96.0933 70.106 96.4549C70.2974 96.8316 70.5761 97.1578 70.9151 97.4071L71.2609
      97.661V106.388C71.2609 106.48 71.2971 106.563 71.3558 106.621L71.3589 106.624C71.4169 106.682 71.4997
      106.719 71.5924 106.719H73.408C73.5006 106.719 73.5827 106.683 73.6415 106.624L73.6445 106.621C73.7025
      106.563 73.7394 106.48 73.7394 106.388V97.6618L74.0852 97.4079C74.425 97.1585 74.7037 96.8323 74.8943
      96.4557C75.0774 96.0941 75.1813 95.682 75.1813 95.2428C75.1813 94.5022 74.8815 93.8318 74.3964
      93.3466L74.3963 93.3458Z"
      fill="white"
    />
  </svg>
);
export const StatusDot = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    width="5px"
    y="0"
    enableBackground="new 0 0 31.955 31.955"
    version="1.1"
    viewBox="0 0 31.955 31.955"
    xmlSpace="preserve"
  >
    <g fill="#030104">
      <path
        d="M27.25 4.655c-6.254-6.226-16.37-6.201-22.594.051-6.227 6.254-6.204 16.37.049
       22.594 6.256 6.226 16.374 6.203 22.597-.051 6.224-6.254 6.203-16.371-.052-22.594z"
      />
      <path
        d="M13.288 23.896l-1.768 5.207c2.567.829 5.331.886
          7.926.17l-.665-5.416a8.3828.382 0 01-5.493.039zM8.12 13.122l-5.645-.859a13.856
          13.856 0 00.225 8.143l5.491-1.375a8.3918.391 0 01-.071-5.909zm20.643-1.789l-4.965
          1.675a8.39 8.39 0 01-.247 6.522l5.351.672a13.868 13.868 0 00-.139-8.869zm-17.369-8.45l1.018
          5.528a8.395 8.395 0 016.442-.288l1.583-5.137a13.85513.855 0 00-9.043-.103z"
      />
      <circle cx="15.979" cy="15.977" r="6.117" />
    </g>
  </svg>
);
